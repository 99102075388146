import Phone from "../../assets/fontawesome/image/phone.png";
export default function IconPhoneAndZalo() {
  let contact = localStorage.getItem("CONTACT");
  console.log(contact);

  const handleClickZalo = (e) => {
    console.log(contact);
  };

  return (
    <div className="fixed bottom-32 right-0 z-50 w-[62px] h-[120px] flex flex-wrap justify-center max-[376px]:bottom-[80px]">
      <a href={`tel:02836222399`} className="h-10">
        <img src={Phone} className="w-[52px]" />
      </a>
    </div>
  );
}