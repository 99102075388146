import { useNavigate, useParams } from "react-router-dom";
import HEADER from "../../assets/fontawesome/image/umx-details.png"
import VIDEO from "../../assets/fontawesome/image/video.png"
import BTN_UMX from "../../assets/fontawesome/image/btn-umx.png"
import BG_VIDEO from "../../assets/fontawesome/image/bg-video.png"
import { useEffect, useState } from "react";
import { campaignServices } from "../../services/apiService/campaignServices";

export default function UMXHotdetails(){
  const appCode = localStorage.getItem("CAMPAIGN_CODE");
  const [campaignDetail, setCampaignDetail] = useState(null);
  const { id } = useParams();
  const navigation = useNavigate();

  const handleBack = () => {
    navigation(`/${appCode}`);
  };
  const getCampaignDetail = (id) => {
    campaignServices
      .getCampaignDetailApi(+id)
      .then((res) => {
        console.log(res)
        const id_video = res.campaign_clip_url.split("watch?v=")[1]
        console.log(id_video)
        const clip =
        res.campaign_clip_url.replace("watch?v=", "embed/") + `?autoplay=1&mute=1&loop=1&playlist=${id_video}`;
        console.log(clip)
        setCampaignDetail(clip);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCampaignDetail(id);
  }, []);
  const hanldeRedirectTakePhoto = () =>{
    navigation(`/guide-takeaphoto/${id}`)
  }
    return(
        <div>
            <div
                className="z-20 fixed top-8 left-5 h-[58px] w-[58px] cursor-pointer"
                onClick={handleBack}
            >
            <div className="containerNotify__header-btnBack h-[40px] w-[60px] cursor-pointer"></div>
            </div>

            <div><img src={HEADER}/></div>
            <div className="w-full flex justify-center mt-7">
                <div className="rounded-2xl mt-[2px] max-w-[100vw] h-[213px] text-xs m-auto relative group">
                  <div className="relative">
                    <img src={BG_VIDEO} className="w-screen h-[280px]"/>
                      <iframe
                      width="100%"
                      height="195px"
                      style={{ fontSize: "3px", borderRadius: "20px", width:"80vw", maxWidth:"430px" }}
                      src={campaignDetail}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                      className="absolute top-10 left-1/2 -translate-x-1/2"
                    ></iframe>
                  </div>
                  
                </div>
            </div>
            <div className="mt-20">
                <div className="bg-tree px-3">
                    <div className="px-2 bg-[#ffffff] opacity-90 border-bg-umx text-[14px] py-3">
                        <div className="font-semibold-mon">Điều kiện áp dụng</div>
                        <div>- Thời gian tham gia chương trình: <span className="font-semibold-mon">13/12 - 27/12/2023</span></div>
                        <div>- Cơ chế chương trình: Mua <span className="font-semibold-mon">499.000đ</span> các sản phẩm  P&G nhận vòng quay may mắn trúng 100%:</div>
                        <div>Giải đặc biệt: Nhẫn vàng SBJ</div>
                        <div>Giải nhất: Tai nghe Airpod</div>
                        <div>Giải nhì: Máy tính bảng</div>
                        <div>Hàng ngàn Voucher Urbox 30.000đ/ 50.000đ</div>
                        <div>- Mọi thắc mắc khách hàng vui lòng liên hệ hotline <span className="font-semibold-mon">(028) 36222399</span> hoặc Email: <span className="font-semibold-mon">contact@mvc.com</span></div>
                    </div>
                    <div className="flex justify-center mt-5">
                        <button onClick={hanldeRedirectTakePhoto}>
                            <div className="relative">
                            <img src={BTN_UMX}/>
                            <div className="absolute top-1/2 -translate-y-1/2 text-white font-bold-mon text-[23px] left-1/2 -translate-x-1/2 w-full -mt-1">Tham gia ngay</div>
                            </div>
                        </button>
                    </div>
                </div>
               
            </div>
        </div>
    )
}